@import "solcreditoBase";

.parallax-product-blocks {
  @media only screen and (min-width: 768px) {
    min-height: 659px;
  }

  .hero-text {
    height: 124px;
    display: flex;
    align-items: flex-end;
    width: 72%;

    @media only screen and (min-width: 768px) {
      padding-top: 115px;
      padding-bottom: 43px;
      width: 100%;
      padding-left: 0;
    }

    h1 {
      text-shadow: 0 2px 6px #2b2e42;

      @media only screen and (min-width: 768px) {
        text-shadow: none;
      }
    }
  }

  .product-blocks-wrapper {
    padding-top: 10px;

    @media only screen and (min-width: 768px) {
      min-width: 400px;

      .product-block {
        margin-top: 115px;
        background-color: #fff;
      }
    }
  }
}

.product-block {
  @media only screen and (min-width: 768px) {
    padding: 24px;
    box-shadow: 0 20px 35px 5px rgba(83, 85, 103, .5);
  }
}

.products-selection-tabs {
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }

  .products-selection-tab {
    padding: 16px 24px;
    border-radius: 30px;
    margin: 0 16px 16px 0;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: $secondary900;
      color: $color-dark;
      box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
    }
  }
}

.product-apr-container {
  .product-apr-block {
    @media only screen and (min-width: 768px) {
      margin: 16px 0 16px 0;
    }
  }
}
