@import "../colors";

:root {
  --fi-primary: #ffa800;
  --fi-primary-300: #ffd076;
  --fi-secondary: #000;
  --fi-secondary-900: #{$secondary900};
  --fi-secondary-700: #{$secondary700};
  --fi-secondary-600: #a5a5a5;
  --fi-secondary-500: #{$secondary500};
  --fi-secondary-400: #8c8c8c;
  --fi-secondary-200: #{$secondary200};
  --fi-secondary-100: #{$secondary100};
  --fi-error: #ec5249;
  --fi-button-width: 335px;
  --fi-button-margin: 10px;
  --fi-button-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), inset 0 -2px 0 0 #dd9200;
  --fi-button-shadow-disabled: 0 5px 5px 0 rgba(0, 0, 0, 0.2), inset 0 -2px 0 0 #e3b865;
  --fi-button-color: #fff;
  --fi-disabled-background: #ffd076;
  --fi-process-background: #fbfbf9;
  --fi-process-step-background: linear-gradient(to bottom, #72c6f7 -97%, #fbfbf9 89%, #fbfbf9 89%);
  --fi-disabled-text: #f5f2ed;
  --fi-green: #00c316;
  --fi-light-green: #caed9e;
  --fi-select-border: #1a84c5;
  --fi-blog-product-bg: #1ea4dc;
}

@media only screen and (max-width: 576px) {
  :root {
    --fi-button-width: 100%
  }
}
